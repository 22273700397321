/* Default CSS start */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

:root {
	--ion-color-success: #092615;
	--ion-color-success-rgb: 45,211,111;
}
:focus {
	outline: 0 none !important;
}

:host {
    font-family: 'Open Sans', sans-serif;
}


html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	scroll-behavior: smooth;
}

body,
.inner-scrollform,
body form,
body label,
body input,
body optgroup,
body select {
    font-family: 'Open Sans', sans-serif;
    color: #000;
}
body h1,
body h2, 
body h3,
body h4,
body h5,
body h6 {
    color: #000;
    margin-top: 0;
    margin-bottom: 30px;
} 

body h1 { font-size: 36px; }
body h2 { font-size: 30px; }
body h3 { font-size: 24px; }
body h4 { font-size: 20px; }
body h5 { font-size: 18px; }
body h6 { font-size: 16px; }

:host,
ion-app,
ion-app.ion-page {
	font-size: 16px;
	line-height: 1.2;
	color: #000;
	font-weight: normal;
	text-align: left;
}

ion-content {
    --color: #000;
    --background: #fff;
}

.alert-wrapper{
    position: absolute;
    overflow-y: scroll; 
    
}

.alert-radio-group{
    height: inherit;
}

IonSelect{
    height: 20%;
    width: 20%;
    overflow-y: scroll;
}

img {
	max-width: 100%;
	max-height: 100%;
	width: auto;
	height: auto;
}

a {
	color: #000;
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}

a:hover,
a:focus {
	color: #2E9BEE;
	text-decoration: none;
}

a,
input[type="submit"],
input[type="button"],
button {
	outline: 0 none;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

[type="search"] {
	box-shadow: none;
	padding-bottom: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.margin-right-auto {
    margin-right: auto;
}

.margin-left-auto {
    margin-left: auto;
}
/* Default CSS end */

/* Button css start */
body .button.button-block,
body .button.button-full,
body .button.button-solid,
body .button.button-outline { 
    --background-focused-opacity: 1;
    --background-hover-opacity: 1;
    --background-activated-opacity: 1;
    height: auto;
    --border-width: 1px;
    font-weight: normal;
    text-transform: none;
    font-size: 18px;    
    --border-radius: 5px;
    white-space: normal;
    letter-spacing: 0.8px;
    --padding-start: 50px;
    --padding-end: 50px;
    --padding-top: 20px;
    --padding-bottom: 20px;
}
body .button.button-block,
body .button.button-full,
body .button.button-solid {
    --color: #fff;
    --color-focused: #fff;
    --color-hover: #fff;
    --border-color: #2E9BEE;
    --background: #2E9BEE;
    --background-focused: #414BB2;
    --background-hover: #414BB2;
    --background-activated: #414BB2;
}
body .button.button-outline { 
    --color: #000;
    --color-focused: #fff;
    --color-hover: #fff;
    --border-color: #000;
    --background: #fff;
    --background-focused: #414BB2;
    --background-hover: #414BB2;
    --background-activated: #414BB2;
    --ripple-color: #414BB2;
}
body .button.button-block.secondary-button,
body .button.button-round.secondary-button,
body .button.button-solid.secondary-button {
    --background: #414BB2;
    --background-focused: #2E9BEE;
    --background-hover: #2E9BEE;
    --color: #ffffff;
    --color-focused: #ffffff;
}
body .button.button-block.button-solid {
    --color: #fff;
    --color-focused: #fff;
    --color-hover: #fff;
    --border-width: 0;
    --background: #2E9BEE;
    --background-focused: #414BB2;
    --background-hover: #414BB2;
}
body .button.button-block.button-solid.secondary-button {
    --color: #fff;
    --color-focused: #fff;
    --color-hover: #fff;
    --border-width: 0;
    --background: #2B89E0;
    --background-focused: #414BB2;
    --background-hover: #414BB2;
}
body .button.yellow-button.button-solid {
    --background: #FAC710;
    --border-color: #FAC710;
}
.button-solid ion-icon {
    font-size: 35px;
    margin-right: 10px;
}
.button-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
body .button.button-clear {
    font-size: 16px;
    text-transform: none;
    --color: #000;
    --color-focused: #2B89E0;
    --color-hover: #2B89E0;
    font-weight: normal;
    --background: transparent;
    --background-hover: transparent;
    --background-hover-opacity: 1;
    --background-activated: transparent;
    --background-activated-opacity: 1;
    --background-focused: transparent;
    --background-focused-opacity: 1;
    --highlight-color-focused: transparent;
    --border-color: transparent;
    --border-width: 0;
    --inner-border-width: 0;
    --ripple-color: transparent;
    opacity: 1;
    --padding-top: 0px;
    --padding-bottom: 0px;    
    --padding-start: 0px;
    --padding-end: 0px;
    margin: 0;
}
/* Button css end */

/* Form field css start */
body .native-input.sc-ion-input-md,
body ion-select,
body ion-textarea,
body .sc-ion-textarea-md-h {
    border: 2px solid #d1d1d1;
    border-radius: 5px;
    background-color: #fff;
    height: 48px;
    padding: 5px 20px;
    font-size: 16px;
    --placeholder-color: #d1d1d1;
    --placeholder-font-weight: 300;
    --placeholder-opacity: 1;
}
body ion-textarea, 
body .sc-ion-textarea-md-h {
    height: auto;
    padding: 15px 20px;
    min-height: 70px;
    margin-top: 0;
}
.native-textarea.sc-ion-textarea-md {
    padding: 0;
}
body .native-input.sc-ion-input-md:focus,
body ion-select:focus,
body ion-datetime:focus,
body ion-textarea:focus,
body .sc-ion-textarea-md-h:focus {
    border-color: #444EA7;
}
body .is-invalid .native-input.sc-ion-input-md,
body .is-invalid ion-select,
body .is-invalid ion-datetime,
body ion-select.is-invalid,
body ion-datetime.is-invalid,
body ion-textarea.is-invalid,
body .sc-ion-textarea-md-h.is-invalid {
    border-color: #f00;
}
body ion-datetime {
    align-items: center;
}
.input-with-icon.read-only-input {
    opacity: 0.5;
}
body .native-input.sc-ion-input-md[readonly] {
    background-color: #f8f8f8;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #7C7C7C;
    font-size: 13px;
  }
::-moz-placeholder { /* Firefox 19+ */
    color: #7C7C7C;
    font-size: 13px;
}
:-ms-input-placeholder { /* IE 10+ */
    color: #7C7C7C;
    font-size: 13px;
}
:-moz-placeholder { /* Firefox 18- */
    color: #7C7C7C;
    font-size: 13px;
}
/* Form field css end */

/* Header css start */
.main-header {
    padding: 20px 15px;
    box-shadow: 0 0 5px rgb(0 0 0 / 50%);
    display: flex;
    align-items: center;
    background-color: #fff;
}
.main-header:after {
    display: none;
}
body .main-header .without-back-arrow.button.button-clear {
    display: none;
}
body .main-header .with-back-arrow + .with-back-arrow.with-step-arrow {
    display: block;
}
body .main-header .with-back-arrow + .with-back-arrow {
    display: none;
}
body .main-header .button.button-clear.with-back-arrow {
    position: absolute;
    left: 0;
}
.main-logo {
    text-align: center;
    margin: 0 auto;
}
.main-header .main-logo {
    margin-bottom: 0;
}
.main-header .main-logo ion-img {
    max-width: 90px;
}
body .main-logo .button.button-clear,
body .apart-logo .button.button-clear,
body .button.button-clear.page-link {
    height: auto;
    margin: 0;
}
.main-logo a {
    display: inline-block;
    margin: 0 auto;
}
.logo {
    max-width: 150px;
    margin: 0 auto;
}
body .button.back-buton {
    margin-right: 30px;
    --padding-start: 0;
    --padding-end: 0;
}

.header-page-title {
    margin: 0 auto;
    justify-self: center;
    /* display: none; */
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    z-index: -1;
}
/* Header css end */

/* Footer css start */
ion-footer {
    background-color: #fff;
    padding: 0;
}
ion-footer ion-list {
    display: flex;
    justify-content: center;
}
ion-footer ion-list.list-md {
    margin: 0;
    padding: 0;
}
ion-footer ion-list ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    --inner-border-width: 0;
    padding: 0 25px;
}
ion-footer ion-list ion-item a,
ion-footer p {
    color: #575757;
    font-size: 15px;
}
ion-footer p {
    margin-top: 0;
}
body ion-footer .button.button-clear.page-link {
    font-size: 15px;
}
body .button.button-clear.scroll-to-bottom-btn {
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 99;
    height: auto;
    margin: 0;
    --padding-start: 0;
    --padding-end: 0;
}
body .button.button-clear.scroll-to-bottom-btn {
    font-size: 25px;
    color: #575757;
}

.footer-menu .popover-content,
.footer-mobile-menu {
    background-color: #fff;
}
.footer-menu-button ion-icon {
    font-size: 40px;
    position: relative;
    top: 5px;
}
.footer-mobile-menu ion-item {
    --inner-border-width: 0;
    --padding-start: 0;
    --inner-padding-end: 0;
}
.footer-mobile-menu .button.button-clear {
    width: 100%;
}
.footer-mobile-menu .button div {
    display: flex;
    width: 100%;
    text-align: left;
    align-items: center;
}
.footer-mobile-menu ion-icon {
    flex: 0 0 25%;
    max-width: 25%;
    font-size: 25px;
}
.footer-mobile-menu .button span {
    flex: 0 0 75%;
    max-width: 75%;
}
/* Footer css end */

/* Login ppage start */
.full-height-div {
    height: 100%;
}
.login-header ion-img {
    max-width: 150px;
    margin: 0 auto;
}
.login-header h1 {
    margin-top: 15px;
    text-align: center;
    font-weight: 400;
}
.login-form-row,
.main-row {
    justify-content: center;
    padding: 5vw 0;
    height: 100%;
}
.main-row {
    height: auto;
}
.login-form-row {
    align-items: center;
}
.input-with-icon {
    position: relative;
}
.input-with-icon ion-icon {
    position: absolute;
    top: 0;
    left: 10px;
    bottom: 0;
    margin: auto;
    z-index: 9;
    font-size: 25px;
}
body .input-with-icon .native-input.sc-ion-input-md {
    padding-left: 45px;
}
.login-form .password-field {
    margin-top: 5px;
}
.login-form .forget-password {
    margin-top: 0;
    align-self: center;
}
.login-form .forget-password .button.button-clear,
body .buy-plan-footer .button.button-clear.clear-button-with-border,
body .button.button-clear.clear-button-with-border {
    letter-spacing: normal;
    margin: 0;
    line-height: 1.2;
    --border-radius: 0;
    --color: #414BB2;
    --color-activated: #2E9BEE;
    --color-focused: #2E9BEE;
    --color-hover: #2E9BEE;
    border-bottom: 3px solid #B3B6D7;
    font-size: 18px;
    --padding-start: 0;
    --padding-end: 0;
    margin: 0;
}
.login-form .forget-password .button.button-clear:hover,
.login-form .forget-password .button.button-clear:focus,
body .buy-plan-footer .button.button-clear.clear-button-with-border:hover,
body .buy-plan-footer .button.button-clear.clear-button-with-border:focus,
body .button.button-clear.clear-button-with-border:hover,
body .button.button-clear.clear-button-with-border:focus {
    border-bottom-color: #2E9BEE;
}
body .button.button-clear.secondary-button.clear-button-with-border {
    --color: #2E9BEE;
    --color-activated: #414BB2;
    --color-focused: #414BB2;
    --color-hover: #414BB2;
    border-bottom: 3px solid #90C7F1;
}
body .button.button-clear.secondary-button.clear-button-with-border:hover,
body .button.button-clear.secondary-button.clear-button-with-border:focus {
    border-bottom-color: #B3B6D7;
}
.login-form .create-account-btn {
    margin-top: 3rem;
}
.form-lable {
    margin-bottom: 5px;
    display: block;
    text-align: left;
}
.create-account-btn .form-lable {
    margin-bottom: 10px;
}
.main-logo {
    margin-bottom: 30px;
}
.main-logo .logo {
    max-width: 120px;
}
/* Login page end */

/* Select workspace page start  */
.main-row {
    justify-content: center;
}
ion-card {
    --background: transparent;
    border: 2px solid #808080;
    box-shadow: none;
    border-radius: 0;
    margin: 0 0 15px;
}
ion-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #808080;
    padding: 8px 15px;
}
ion-card-header h6 {
    margin: 0;
    font-weight: 700;
}
ion-card-header ion-button ion-icon {
    font-size: 35px;
}
body ion-card-content.card-content-md {
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-bottom: 0;
    padding-top: 0;
}
ion-card-content ion-item {
    --padding-start: 0;
    /* --inner-padding-end: 0; */
    /* --inner-border-width: 0; */
    height: 100%;
    --inner-padding-start: 15px;
}
.dashboard-button-inner {
    display: flex;
    align-items: center;
    justify-content:flex-start;
    margin-right: auto;
}
body ion-item .button.button-clear {
    width: 100%;
    height: 100%;
    margin: 0;
    --padding-start: 15px;
    --padding-end: 15px;
    --padding-top: 15px;
    --padding-bottom: 15px;
}
ion-card .list-md {
    padding: 0 0 50px;
}
body ion-item ion-icon {
    color: #808080;
    font-size: 40px;
}
body ion-item:hover ion-icon,
body ion-item:hover ion-label,
body ion-item:focus ion-icon,
body ion-item:focus ion-label {
    color: #2B89E0;
}
body ion-card-header .button.button-clear {
    --padding-start: 0;
    --padding-end: 0;
    margin: 0;
}
ion-card.light-pink-card {
    background-color: #F9E3F0;
    padding: 20px 15px 15px;
    display: flex;
    flex-direction: column;
}
ion-card h5 {
    margin-bottom: 15px;
    font-weight: 400;
}
ion-card.light-pink-card ion-button {
    align-self: flex-end;
}
ion-card-header.ion-inherit-color.md.card-header-without-icon {
    padding: 15px;
}
.list-with-seperate-button ion-item {
    --inner-padding-start: 15px;
    --inner-padding-end: 15px;
    --padding-top: 10px;
    --padding-bottom: 10px;    
    --inner-border-width: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}
.list-with-seperate-button .button.button-solid {
    --padding-top: 15px;
    --padding-bottom: 15px;    
    --padding-start: 15px;
    --padding-end: 15px;
    margin: 0;
}
.position-relative {
    position: relative;
}
body .button.button-solid.abosolute-button-with-link,
body .button.button-full.abosolute-button-with-link,
body .button.button-outline.abosolute-button-with-link,
body .button.button-clear.abosolute-button-with-link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
/* Select workspace page end  */

/* Select project page start  */
.select-project-header ,
.back-arrow {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}
.project-title-wrap {
    margin-left: 10px;
}
.project-title-wrap h3, 
.project-title-wrap h6 {
    margin: 0;
    white-space: normal;
    word-break: break-word;
}
.project-title-wrap h6 {
    font-weight: 400;
}
.select-project-header ion-icon,
.back-arrow ion-icon {
    font-size : 40px;
}
body .button.button-clear.edit-project-button {
    margin-left: auto;
}
/* Select project page end  */


/* Registration css start  */
.signup-form {
    flex-direction: column;
}
.signup-form ion-list {
    background: transparent;
}
.signup-form ion-list-header {
    padding: 0;
    visibility: inherit;
    font-size: 16px;
    color: #575757;
    font-weight: 300;
}
.signup-form ion-list-header ion-label {
    margin-bottom: 15px;
}
.user-select-item {
    --background: transparent;
    --border-radius: 0;
    --background-hover: transparent;
    --background-focused-opacity: 1;
    --background-activated: transparent;
    --background-activated-opacity: 1;
    --background-focused: transparent;
    --highlight-color-focused: transparent;
    --background-focused-opacity: 1;
    --border-color: transparent;
    --border-width: 0;
    --inner-border-width: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
}
.user-select-item + .user-select-item {
    margin-top: 15px;
}
.user-select-item:hover,
.user-select-item:focus,
.user-select-item.item-radio-checked,
.user-select-item.ion-activated {
    --background: transparent;
}
.user-select-item.item ion-label {
    color: #575757;
    overflow: visible;
    white-space: normal;
    font-size: 18px;
}
.user-select-item ion-label p {
    margin: 10px 0 0;
    font-size: 15px;
    font-weight: 300;
    color: #575757;
}
.user-select-item ion-radio {
    --color-checked: #424AB4;
    width: 30px;
    height: 30px;
    margin-inline-start: 0;
    margin-inline-end: 15px;
    margin-top: 0;
    margin-bottom: 0;
}
:host(.radio-checked) .radio-icon {
    background: #424AB4;
}
.error-message, 
.error-summary {
    color: red;
    font-size: 14px;
    margin: 2px 0 0;
}
.back-arrow {
    margin-bottom: 25px;
}
.back-arrow h2,
.back-arrow h3,
.back-arrow h4 {
    margin-bottom: 0;
}
form .swiper-slide {
    text-align: left;
    font-size: 16px;
}
.swiper-pagination, 
.swiper-container-horizontal>.swiper-pagination-bullets {
    position: relative;
    bottom: auto;
    left: auto;
}
.ion-margin-auto {
    margin: auto;
}
.form-footer-note p {
    font-size: 14px;
    line-height: 1.4;
    color: #000;
}
.form-footer-note .button.button-clear {
    font-size: 14px;
    line-height: 1.2;
    text-decoration: underline;
    margin: -7px 0 0;
}
.form-step-2 .form-footer-note p {
    display: flex;
    align-items: center;
}
.form-step-2 .form-footer-note .button.button-clear {
    margin: auto 8px;
}
.form-footer-note ion-icon {
    font-size: 25px;
}
.swiper-pagination-bullet {
    background: #e6e6e6;
}
.swiper-pagination-bullet-active {
    background: #2E9BEE;
}
/* Registration css end  */

/* Create project start  */
.form-sub-title {
    margin: 20px 0 10px;
    display: flex;
    align-items: flex-end;
    font-weight: 700;
}
.form-sub-title ion-icon {
    font-size: 30px;
    margin-left: 5px;
}
.date-picker {
    position: relative;
}
body .date-picker .button.button-clear {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
    left: 0;
    right: 0;
    padding: 0 15px;
}
.date-picker ion-icon {
    font-size: 20px;
    color: #000;
    margin-left: auto;
}
.form-lable h4 {
    font-size: 20px;
}
.building-info ion-icon.md {
    font-size: 30px;
}
.add-more-building-info {
    margin-top: 10px;
}
.add-more-building-info .button.button-solid.secondary-button {
    --padding-top: 12px;
    --padding-bottom: 12px;
    --padding-start: 30px;
    --padding-end: 30px;
}
.building-info .button.button-clear {
    height: 48px;
    margin-top: 25px;
}
.form-sub-title + .building-info .button.button-clear {
    display: none;
}
.date-picker-popup {
    --width: 400px;
    --height: 400px;
    padding: 15px;
}
.date-picker-popup #ion-react-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
}
.date-picker-popup ion-grid {
    padding: 0;
}
ion-datetime {
    margin: auto;
    width: 100%;
    max-width: 100%;
}
/* Create project end  */

/* Dashboard start  */
.main-title-with-icons-on-right {
    width: 100%;
}
.buttons-wrap ion-icon {
    font-size: 32px;
}
body .buttons-wrap .button + .button {
    margin-left: 10px;
}
body .dashboar-buttons .button.button-outline {
    --padding-top: 15px;
    --padding-bottom: 15px;
    --padding-start: 15px;
    --padding-end: 15px;
    margin: 0;
    --border-radius: 0;
    --border-width: 2px;
}
body .dashboar-buttons ion-icon {
    font-size: 65px;    
    stroke: #000;
}
body .dashboar-buttons .button:hover ion-icon {
    stroke: #fff;
}
.dashboar-buttons h6 {
    margin: 10px 0 0;
    font-weight: 700;
}
body .dashboar-buttons .button.button-outline:hover h6,
body .dashboar-buttons .button.button-outline:focus h6,
body .dashboar-buttons .button.button-outline:hover ion-icon,
body .dashboar-buttons .button.button-outline:focus  ion-icon {
    color: #fff;
}
.dashboar-buttons {
    margin: 0 -15px;
}
.dashboar-buttons ion-col {
    padding: 15px;
}
/* Dashboard end  */

/* My Accout page start  */
.my-account-header {
    margin-bottom: 120px;
}
/* My Accout page end  */

/* Users start  */
.invite-user-button ion-icon {
    font-size: 40px;
    margin-right: 10px;
}
body .button.button-clear.invite-user-button:hover h5, 
body .button.button-clear.invite-user-button:focus h5 {
    color: #2B89E0;
}
.table {
    border: 1px solid #808080;
    padding: 0;
    border-top: none;
    margin-top: 15px;
}
.table-row {
    border-top: 1px solid #808080;
}
.table-row ion-col {
    padding: 15px 10px;
}
.table-header h6 {
    font-weight: 700;
}
.table-row p {
    margin: 0;
}
.user-name {
    font-weight: 700;
}
.user-name.active-user {
    color: #8FD14F;
}
.user-number.active-user-number {
    color: #000;
}
.user-name,
.user-number,
.user-type {
    color: #808080;
}
/* Users end  */

/* Edit User start  */
.user-name-display {
    display: flex;
    margin: 40px 0 30px;
}
.user-name-display h3 {
    margin: 0;
}
.user-name-display h3 + h3 {
    margin-left: 10px;
}
.checkbox-wrap {
    display: flex;
    align-items: center;
    margin-top: 15px;
    display: inline-block;
    align-items: center;
    margin-top: 15px;
    --inner-border-width: 0;
    --min-height: auto;
    --background: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    --ripple-color: transparent;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
}
.checkbox-wrap ion-checkbox {
    margin: 0;
    --border-color: #d1d1d1;
    --border-color-checked: #414BB2;
    --background-checked: #414BB2;
    --checkmark-color: #fff;
}
.checkbox-wrap .form-lable {
    margin: 0 0 0 10px;
    font-size: 16px;
    color: #000;
    position: relative;
    top: 1px;
}
/* Edit User end  */

/* Billing start  */
.project-number {
    font-weight: 400;
}
.table-footer-note {
    margin: 40px 0 0;
}
.table-title {
    margin: 40px 0 10px;
}
/* Billing end  */

/* Buy Plan start  */
.table-row ion-icon {
    font-size: 35px;
}
.subsciption-note {
    border: 1px solid #808080;
    color: #808080;
    padding: 12px;
    margin: 70px 0;
    font-size: 14px;
}
.subsciption-note p {
    margin: 0;
    line-height: 1.4;
}
.buy-plan-footer {
    text-align: center;
}
.buy-plan-footer .main-logo {
    max-width: 40px;
    margin: 0 auto 15px;
}
body .buy-plan-footer .button.button-clear.clear-button-with-border {
    font-size: 16px;
}
.buy-plan-footer p {
    font-size: 14px;
    margin: 20px 0 0;
}
/* Buy Plan end  */

/* Inward start  */
.margin-bottom-invite{
    margin-bottom: 15px;
}
.category-name {
    padding-top: 15px;
    border-top: 1px solid #808080;
    margin-bottom: 15px;
    padding-left: 5px;
}
.category-list {
    padding: 0;
    border-bottom: 1px solid #808080;
    margin-bottom: 50px;
    max-height: 50%;
    overflow-y: scroll;
    background: #fff;
}
.category-button {
    border-top: 1px solid #808080;
    --inner-padding-start: 0;
    --padding-start: 15px;
    --inner-border-width: 0;
    --inner-padding-end: 0;
    --padding-end: 20px;
    --min-height: auto;
    height: auto;
    font-size: 18px;
    --background-focused-opacity: 1;
    --background-hover-opacity: 1;
    --background-activated-opacity: 1;
    --color: #000;
    --color-focused: #fff;
    --color-hover: #fff;
    --background: #fff;
    --background-focused: #2E9BEE;
    --background-hover: #2E9BEE;
    --background-activated: #2E9BEE;
}
.category-button:hover,
.category-button:focus {
    --background: #2E9BEE;
}
body .category-button ion-icon {
    font-size: 35px;
    color: #000;
}
body ion-item.category-button:hover ion-icon, 
body ion-item.category-button:hover ion-label, 
body ion-item.category-button:hover .current-stock h5, 
body ion-item.category-button:focus ion-icon, 
body ion-item.category-button:focus ion-label,
body ion-item.category-button:focus  .current-stock h5 {
    color: #fff;
}
/* Inward end  */

/* Inward entry start  */
.material-add-form ion-icon {
    font-size: 35px;
}
.material-add-form .form-lable {
    font-size: 18px;
}
.form-lable.material-lable {
    margin: 0;
    padding-top: 15px;
}
.material-select-row + .material-select-row {
    margin-top: 10px;
}
body .button.button-solid.small-button {
    --padding-top: 12px;
    --padding-bottom: 12px;
    --padding-start: 20px;
    --padding-end: 20px;
}
.material-add-form .small-form-lable {
    font-size: 14px;
}
body .size-select-row ion-select {
    padding: 5px;
}
.size-select-row .form-lable.material-lable:not(.small-form-label) {
    padding-top: 40px;
}
.form-lable.two-line-label {
    padding-top: 5px;
}
body .red-alert .alert-head.sc-ion-alert-md {
    background-color: #FF6363;
}
.uploaded-file {
    display: flex;
    align-items: center;
}
.uploaded-file + .uploaded-file {
    margin-top: 15px;
}
.uploaded-file-name {
    width: 300px;
    border: 1px solid #7c7c7c;
    background-color: #fff;
    border-radius: 15px;
    min-height: 48px;
    height: auto;
    display: flex;
    align-items: center;
    line-height: 1.2;
    padding: 0 20px;
    margin: 0;
    font-family: 'Segoe UI';
    font-size: 16px;
    font-weight: 600;
}
.uploaded-file-name.read-only {
    border-color: transparent;
    background-color: #e5e5e5;
    white-space: normal;
    word-break: break-all;
}
.uploaded-file ion-icon {
    color: #FF6363;
    font-size: 30px;
}
.upload-photo-block {
    margin-top: 15px;
}
body .button.upload-photo-btn.yellow-button.button-solid {
    --padding-start: 15px;
    --padding-end: 15px;
    width: 100%;
}
body .photo-buttons.buttons-wrap .clear-button-with-border
{
    font-size: 16px;
    margin-left: 0;
    margin-right: 10px;
}
.uploaded-file-name.read-only:empty, 
.uploaded-file-name.read-only:empty + .button {
    display: none;
}
body .alert-head.sc-ion-alert-md {
    text-align: center;
    color: #fff;
}
body .alert-title.sc-ion-alert-md {
    color: #fff;
    font-weight: 600;
}
/* Inward entry end  */

/* Material stock start  */
.current-stock h3, 
.current-stock h5, 
.current-stock p {
    margin: 0;
    text-align: center;
}
.current-stock h3,
.current-stock h5 {
    color: #0CA789;
}
.stock-buttons.buttons-wrap {
    text-align: right;
    margin-bottom: 15px;
}
.buttons-wrap .export-button ion-icon {
    font-size: 35px;
}
.current-stock p {
    font-size: 14px;
}
/* Material stock end  */

/* Stock report start  */
.stock-report-title {
    margin-bottom: 15px;
}
.stock-report-title .current-stock {
    display: flex;
    align-items: center;
}
.stock-report-title .stock-buttons.buttons-wrap {
    margin: 0;
}
.stock-report-title .current-stock p {
    font-size: 16px;
    margin-left: 5px;
    padding-top: 2px;
}
.report-table {
    text-align: left;
    margin-top: 0;
}
ion-segment {
    justify-content: space-between;
}
ion-segment-button {
    --color-checked: #414BB2;
    --color-selected: #414BB2;
    --color-focused: #414BB2;
    overflow: hidden;
    flex: 0 0 auto;
    --padding-start: 0;
    --padding-end: 0;
    text-transform: none;
    font-size: 16px;
    --color: var(--color);
}
ion-segment-button.segment-button-checked {
    --border-color: #414BB2;
    border-bottom-color: #414BB2;
    font-weight: normal;
}
.person-name {
    color: #652CB3;
}
.report-table p {
    line-height: 1.4;
}
.photo-buttons {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.quantity-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.photo-popup .ion-page {
    position: relative;
    width: 100%;
    height: 100%;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
}
body .button.button-clear.close-popup-button {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    font-size: 20px;
    width: auto;
    min-width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    --color: #2E9BEE;
}
ion-slide {
    padding: 0 1px;
}
.chart {
    margin: 50px 0;
}
/* Stock report end  */

/* Spalsh screen start */
.splash-screen-content ion-grid {
    padding: 30px 0;
}
.splash-screen-content ion-row {
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.splash-screen-image {
    margin-bottom: 50px;
}
.splash-screen-header h3 {
    margin-top: 20px;
    font-weight: 400;
}
.app-name {
    font-weight: 400;
    margin-top: 50px;
    color: #1a1a1a;
}
.app-version {
    margin: 0;
    color: #1a1a1a;
    font-weight: normal;
    margin-top: 70px;
}
/* Spalsh screen end */


ion-toast {
    text-align: center;
    --max-width: 100%;
    --min-width: auto;
 
    --width: max-content;
}
/*
body .alert-wrapper.sc-ion-alert-md {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 280px;
}
body .alert-radio-group.sc-ion-alert-md, 
body .alert-checkbox-group.sc-ion-alert-md {
    max-height: 100%;
    overflow: visible;
} */



.category-list
{
    max-height: 65vh;
    overflow-y: auto;
}
.alert-wrapper span.alert-button-inner.sc-ion-alert-md
{
    text-transform: uppercase;
}
.swiper-pagination-bullet
{
    opacity: 0.8;   
} 
:host .datetime-year
{
    max-width: 100%;
}
.alert-wrapper.sc-ion-alert-md
{
    overflow: auto;
}
body ion-datetime
{
    align-items: stretch;
}


/* 404 page start */
.page-not-found-section h1 {
    font-size: 55px;
    margin: 35px 0 25px;
}
.page-not-found-section h6 {
    margin: 0;
}
/* 404 page end */
/* 

font-family: 'Open Sans', sans-serif;
light: 300
regular: 400
medium: 500
semi-bold: 600
bold: 700

body h1 { font-size: 36px; }
body h2 { font-size: 30px; }
body h3 { font-size: 24px; }
body h4 { font-size: 20px; }
body h5 { font-size: 18px; }
body h6 { font-size: 16px; }

color: #000000
Blue color 1: #2E9BEE;
Dark blue color 2: #414BB2;

*/

.date-picker-popup .react-date-picker__calendar.react-date-picker__calendar--closed {
    display: block;
}
.date-picker-popup .react-date-picker__wrapper {
    display: none;
}
.date-picker-popup {
    --width: 350px;
    --height: 340px;
}
.building-info .native-input.sc-ion-input-md {
    padding: 8px;
}
/* .date-picker-button {
    padding: 9px 0;
}  */
/* 
.photo-popup #ion-react-wrapper,
.photo-popup ion-img
{
    height: 500px!important;
} */
.photo-popup
{
    --height: auto!important;
}
.photo-popup .modal-wrapper
{
    --height: auto;
    
}
.loader-image {
    width: 60px;
    position: fixed;
    left: 0;
    top: 0;
    margin: auto;
    height: 60px;
    right: 0;
    bottom: 0;
    z-index: 999 
}
.building-info ion-row
{
    align-items: flex-end;
}
body ion-button.generate-btn.button
{
    height: 47px!important;
    --padding-start: 30px;
    --padding-end: 30px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    margin: 0;
    width: 100%;
    
}
ion-col.text-center
{
    text-align: center;
}


ion-item .select-text {
    display: flex;
    align-items: center;
  }
  
  ion-item .select-icon {
    margin-left: auto;
  }

.alert-radio-inner.sc-ion-alert-md {
    left: 3.5px!important;
    top: 3.5px!important;
}


.load-more-button, .load-more-button-disabled{
    display: flex;
    color: white;
    font-size: 20px !important
}


.load-more-button-disabled{
    color: black !important;
}



ion-button.load-more-button{
    --background-hover: #2B89E0 !important;
    --background: #2B89E0 !important;
    --background-focused: #2B89E0 !important;
    --color-focused	: white !important;
    --color-hover: white !important;
}

.breadcrumb-active{
    color: black;
    font-weight: bold;
}

.building-info ion-row {
    align-items: center;
}
    

ion-breadcrumbs {
    padding: 20px 0px;
}

.stock-report-container{
    display: flex;
    align-items: center;
    gap: 10px;
}

.stock-report-container .stockMaterial {
    font-weight: 700;
}
.stock-report-container .categoryName {
    font-weight: 400;
}


.current-stock-container{
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 25px 0px;
}
.stock-inward-particular, .stock-outward-particular{
    justify-content: center;
    display: flex;
}


.stock-inward-particular p, .stock-outward-particular p{
    padding: 0px 2.5px;
}

/* .category-name-padding{
    padding-left: 0px !important;
} */

.font20{
    font-size: 20px;
}


.top-border-with-padding{
    border-top: 1px solid #808080;
    padding-top: 15px;
}

.photo-modal {
    max-height: 90vh;
}

.search-row{
    display: flex;
    align-items: center;
}

.alert-radio-label.sc-ion-alert-md {
    white-space: pre-wrap;
}

.font-14 {
    font-size: 14px;
}

.note-role{
    max-width: fit-content;
}

.tooltip-container{
    display: flex;
    gap: 10px;
}
.ion-no-verticle-padding
{
    padding-top: 0!important;
    padding-bottom: 0!important;

}
ion-content {
    --padding-top: var(--ion-safe-area-top, 0);
  }