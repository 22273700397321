@media (prefers-color-scheme: dark) {
    ion-list,
    ion-list ion-item,
    ion-card,
    ion-card-content {
        --background: #fff;
        --color: #000;
    }
    ion-card .list-md {
        background: #fff;
    }
}

@media (min-width: 1200px) { 

    
}


@media (min-width: 992px){
    
}


@media (max-width: 1366px) { 

    

}


@media (max-width: 1199px) {

    

}


@media (max-width: 991px) {

    /* header css start */
    body .button.back-buton,
    body .button.button-clear {
        z-index: 1;
    }
    /* header css end */

}


@media (max-width: 768px) {
    /* body .dashboar-buttons .button.button-outline {
        height: 169px;
    } */
}


@media (max-width: 767px) {

    /* header css start */
    .logo {
        max-width: 36vw;
    }
    /* header css close */

    /* Select workspace start */
    .login-form-row,
    .main-row {
        padding: 30px 15px 0;
    }
    /* Select workspace end */

    /* Registration start  */
    .slide-main.ion-padding {
        padding: 0;
    }
    .login-form .forget-password .button.button-clear {
        font-size: 14px;
    }
    /* Registration end  */

    /* Dahsboard start  */
    body .dashboar-buttons .button.button-outline:hover h6,
    body .dashboar-buttons .button.button-outline:focus h6,
    body .dashboar-buttons .button.button-outline:hover ion-icon,
    body .dashboar-buttons .button.button-outline:focus  ion-icon {
        color: #000;
    }
    /* Dahsboard end */

    /* 28-oct-2022 */
    .error-message, .error-summary
    {
        font-size: 13px;
    }
    /* 28-oct-2022 End*/

}


@media (max-width: 767px) and (orientation : landscape)  {

    /* header css start */
    .main-header {
        padding: 15px 0;
    }
    .logo {
        max-width: 100px;
    }
    /* header css close */
}


@media screen and (max-width: 575px) {

    /* header css start */
    .main-header {
        border-radius: 0 0 10px 10px;
        padding: 25px 0;
    }
    .header-page-title {
        font-size: 20px;
    }
    /* header css close */


    .load-more-button, .load-more-button-disabled {
        font-size: 20px !important;
    }
    /* button css start */
    body .button.button-block,
    body .button.button-full, 
    body .button.button-solid,
    body .button.button-outline {
        font-size: 16px;
        --padding-start: 30px;
        --padding-end: 30px;
    }
    /* button css end */

    /* login css start */
    .login-header ion-img {
        max-width: 100px;
        margin: 0 auto;
    }
    /* login css end */

    /* USers start  */
    .table {
        margin: 15px -26px 0;
    }
    /* USers end  */

    /* Buy Plan start  */
    .subsciption-note,
    .category-name,
    .category-list {
        margin-left:-26px;
        margin-right:-26px;
    }
    /* Buy Plan end  */

    /* Stock Report start  */    
    .stock-report-tabs {
        margin-left: -26px;
        margin-right: -26px;
    }
    ion-segment {
        padding: 0 15px;
    }
    .report-table {
        margin: 0;
    }
    ion-slide {
        padding: 0;
    }
    /* Stock Report end  */

    .material-add-form .form-lable {
        font-size: 13px;
        padding-top: 17px;
    }
    .material-add-form p {
        font-size: 13px;
        margin-left: -8px;
        padding-top: 3px;
    }
    .category-list {
        max-height: 80%;
    }    
    body ion-button.generate-btn.button
    {
        --padding-start: 10px;
    --padding-end: 10px;
    }


    .category-name-padding {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.chart #ResponsiveContainer #PieChart #Pie {
    outerRadius : 95;
}


@media (max-width: 390px) {
    /* body .dashboar-buttons .button.button-outline {
        height: 155px;
    } */
}

@media (max-width: 375px) {
}

@media (max-width: 320px) {
    /* body .dashboar-buttons .button.button-outline {
        height: 147px;
    } */
}